const dataTypes = {
	UPDATE_FILE: 'UPDATE_FILE',
	REMOVE_FILES: 'REMOVE_FILES',
	UPDATE_PROCESSED_FILE: 'UPDATE_PROCESSED_FILE',
	UPLOADING: 'UPLOADING',
	RESET: 'RESET',
	SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
	UNSET_ALERT_MESSAGE: 'UNSET_ALERT_MESSAGE',
	EMPTY_DATA: 'EMPTY_DATA',
};

export default dataTypes;
