import React, {
	useReducer,
	createContext,
	useMemo,
	useContext,
	useCallback,
	useRef,
} from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import dataReducer, { dataInitialState } from './reducer';
import dataTypes from './types';
import { api_url } from '../../utils/constants';

const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

// Context
export const DataContext = createContext();

var redirectDomain = ''
var bucketName = ''

const isBrowser = () => typeof window !== "undefined"

/* ################### MULTI DOMAIN LOGIC ######################## */

const domain = isBrowser() && window.location.hostname
	.replace('www.', '')

switch (domain) {
	case 'passportprintsnow.com':
		redirectDomain = 'https://www.photoprintsnow.com/passport-photos'
		bucketName     = 'passport-prints-now-webapp'
		break;
	case 'photoidprints.com':
		redirectDomain = 'https://app.fotofirst.co.za/passport-photos'
		bucketName     = 'photo-id-prints-webapp'
		break;
	case 'passportprint.com':
		redirectDomain = 'https://www.printicular.com/us/passport-photos'
		bucketName     = 'passport-photo-webapp'
		break;
	case 'passportprintsplus.com':
		redirectDomain = 'https://www.photoprints.app/passport-photos'
		bucketName     = 'passport-prints-plus-webapp'
		break;
	case 'passportprint.co.nz':
	case 'passportprint.co':
		redirectDomain = 'https://www.printicular.com/nz/passport-photos'
		bucketName     = 'missing-bucket' 
		break;
	default:
		// isBrowser() && console.error(`Configuration error, cant find domain ${domain}.`);
	}
	  
/* ################### MULTI DOMAIN LOGIC END ######################## */

function DataProvider(props) {
	
	const [state, dispatch] = useReducer(dataReducer,dataInitialState);
	const webcamRef = useRef(null);


	function uploadImageToBackend(endpoint, callback){
		
		var file_to_send = ''
		
		// Send processed image if there's one
		if(state.processedImage){
			file_to_send = state.processedImage
		}
		else {
			file_to_send = state.file
		}

		// For mobile
		if(file_to_send instanceof Blob){
			let formData = new FormData();
			formData.append('image', file_to_send)
			formData.append('bucket', bucketName)

			axios({
				method: "post",
				url: `${api_url}/api/${endpoint}`,
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(callback)
			.catch((err) => {
				sendErrorToGoogleAnalytics()
				console.error('Error uploading image', err);
			});
		}
		// For browser
		else {
		
			axios
				.post(`${api_url}/api/${endpoint}`, { file: file_to_send, bucket: bucketName })
				.then(callback)
				.catch((err) => {	
					sendErrorToGoogleAnalytics()
					console.error('Error uploading image', err);
				});

		}
	}

	const redirectToRetailer = (data) => {
		// Google Analytics Event
		window.dataLayer.push({'event': 'continuation-to-retailer'});
		navigate(redirectDomain + '/?image=' + encodeURIComponent(data));
	}

	const sendErrorToGoogleAnalytics = () => {
		// Google Analytics Event
		window.dataLayer.push({'event': 'fatal-error'});
	}

	const updateFile = useCallback((file, cb) => {
		const imagePreview = URL.createObjectURL(file);

		console.log('file', file)
		dispatch({
			type: dataTypes.UPDATE_FILE,
			payload: {
				file,
				imagePreview,
			},
		});

		cb && cb();
	}, []);

	const emptyData = useCallback(() => {
		dispatch({
			type: dataTypes.EMPTY_DATA,
		});
	}, []);

	const capture = useCallback(() => {
		setTimeout(() => {
			var img = webcamRef.current.getScreenshot({width: 1200, height: 900})
			
			dispatch({
				type: dataTypes.UPDATE_FILE,
				payload: {
					file: img,
					imagePreview: img,
				},
			});
		}, 3000);
	}, [webcamRef]);

	// Accepts an optional object with the key alert_message
	const retakeImage = useCallback(({alert_message} = '') => {
		dispatch({
			type: dataTypes.REMOVE_FILES
		});

		navigate('/image-preview',{ 
			state: {
				alert_message
			}
		});
	}, []);

	// console.log('image in mb', state);

	const uploadImageMB = useCallback(async () => {
		dispatch({
			type: dataTypes.UPLOADING,
			payload: true,
		});

		const base64 = await toBase64(state.file);

		axios
			.post(`${api_url}/api/image-upload`, { file: base64 })
			.then((res) => {
				dispatch({
					type: dataTypes.UPDATE_FILE,
					payload: {
						imagePreview: res.data.secure_url,
						file: res.data.secure_url,
						bucket: bucketName
					},
				});

				redirectToRetailer(res.data)
			})
			.catch((err) => {

				sendErrorToGoogleAnalytics()
				console.error('Error uploading image', err);
				dispatch({
					type: dataTypes.UPLOADING,
					payload: false,
				});
			});
	}, [state]);


	const uploadImageForProcessing = useCallback(() => {


		// Receives processed image as a Base64 string
		const handleRequest = (res) => {

			let errors = {
				'no-face-detected': 'Our system did not detect a face in the image, please try again'
			}

			if(res.data.status === 'error'){
				dispatch({
					type: dataTypes.UPLOADING,
					payload: false
				})

				dispatch({
					type: dataTypes.SET_ALERT_MESSAGE,
					payload: errors[res.data.message]
				})

				retakeImage({alert_message: errors[res.data.message] })
				
				return
			}

			console.log('processed image received', res)

			// Google Analytics Event
			window.dataLayer.push({'event': 'face-detection'});

			let new_state = {
				image: "data:image/png;base64," + res.data.image,
				image_features: res.data.picture_features
			}

			
			dispatch({
				type: dataTypes.UPDATE_PROCESSED_FILE,
				payload: new_state
			});
			
			navigate('/image');

		}
	
		uploadImageToBackend('image-upload-for-processing', handleRequest)

	}, [state]);

	// This method is used once the user confirms the already processed image
	const uploadImageToS3 = useCallback(() => {
		dispatch({
			type: dataTypes.UPLOADING,
			payload: true,
		});

		const handleRequest = (res) => {
			redirectToRetailer(res.data)
		}

		uploadImageToBackend('direct-s3-upload', handleRequest)
	}, [state]);

	const setAlertMessage = useCallback((message) => {
		dispatch({
			type: dataTypes.SET_ALERT_MESSAGE,
			payload: message
		})
	})

	const unsetAlertMessage = useCallback((message) => {
		dispatch({
			type: dataTypes.UNSET_ALERT_MESSAGE,
			payload: ''
		})
	})

	const value = useMemo(() => {
		return {
			state,
			updateFile,
			webcamRef,
			capture,
			retakeImage,
			uploadImageForProcessing,
			uploadImageToS3,
			uploadImageMB,
			setAlertMessage,
			unsetAlertMessage,
			emptyData
		};
	}, [state, updateFile, capture, retakeImage, uploadImageForProcessing, uploadImageToS3, uploadImageMB]);

	return (
		<DataContext.Provider value={value} {...props}>
			{props.children}
		</DataContext.Provider>
	);
}

export const useData = () => useContext(DataContext);

export default DataProvider;
