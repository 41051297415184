import React from 'react';
import DataProvider from './src/context/data';
import "@fontsource/merriweather" // Defaults to weight 400 with all styles included.

export const wrapRootElement = ({ element }) => (
	<DataProvider>{element}</DataProvider> 
);

export const onInitialClientRender = ({laodPage}, param2) => {

	// When back button is pressed
	window.addEventListener('popstate', () => {

		// always reload to first page
		window.location = '/'

		return null
	})
}
