exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-404-js": () => import("./../../../src/pages/dev-404.js" /* webpackChunkName: "component---src-pages-dev-404-js" */),
  "component---src-pages-final-js": () => import("./../../../src/pages/final.js" /* webpackChunkName: "component---src-pages-final-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-image-js": () => import("./../../../src/pages/image.js" /* webpackChunkName: "component---src-pages-image-js" */),
  "component---src-pages-image-preview-js": () => import("./../../../src/pages/image-preview.js" /* webpackChunkName: "component---src-pages-image-preview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-processing-js": () => import("./../../../src/pages/processing.js" /* webpackChunkName: "component---src-pages-processing-js" */)
}

