import dataTypes from './types';

export const dataInitialState = {
	file: null,
	imagePreview: null,
	uploading: false,
	loading: false,
	alert_message: '',
	processedImage: '',
	imageFeatures: {}
};

export default function dataReducer(state = dataInitialState, action) {
	switch (action.type) {
		case dataTypes.UPDATE_FILE:
			return {
				...state,
				file: action.payload.file,
				imagePreview: action.payload.imagePreview,
				uploading: false,
			};

		case dataTypes.REMOVE_FILES:
			return {
				...state,
				file: '',
				imagePreview: '',
				processedImage: '',
				uploading: false,
			};

		case dataTypes.UPDATE_PROCESSED_FILE:
			return {
				...state,
				processedImage: action.payload.image,
				imageFeatures: action.payload.image_features,
				uploading: false,
			};

		case dataTypes.UPLOADING:
			return {
				...state,
				uploading: action.payload,
			};

		case dataTypes.SET_ALERT_MESSAGE:
			return {
				...state,
				alert_message: action.payload
			};

		case dataTypes.UNSET_ALERT_MESSAGE:
			return {
				...state,
				alert_message: ''
			};

		case dataTypes.empty_data:
			return {
				...state,
				file: null,
				imagePreview: null,
				processedImage: null,
				alert_message: null,
				uploading: false,
				loading: false,
			};

		default:
			return state;
	}
}
